import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

const Info = () => (
  <Section id="om" accent="secondary">
    <Container style={{ position: 'relative' }}>
      <AboutDiv>
        <h1>Trenger du plass til din campingvogn eller andre eiendeler?</h1>
        <br />
        <p>
          Trenger du plass til din campingvogn, et flyttelass, bilhjul, møbler
          eller sykler? Da kan vi hjelpe deg!
        </p>
        <br />
        <p>
          Vi i Låvelager tilbyr vinterlagring av din campingvogn og
          helårslagring av andre eiendeler, i Indre Østfold. I vårt uisolerte
          låvelager står campingvognen trygt og tørt, og i nyoppussete
          oppvarmede lokaler tilbyr vi vårt minilager med boder på
          størrelsene ca. 11m2 eller ca. 20m2.
        </p>
        <br />
        <p>
          Låvelager ligger sentralt med kort vei til Mysen, Rakkestad, Askim og
          i nærheten til Sarpsborg og Ski. Ta kontakt slik at vi kan hjelpe deg
          med lagring av stort og smått!
        </p>
        <br />
        <p>
          Ta kontakt slik at vi kan hjelpe deg med lagring! Anders Uvaag{' '}
          <StyledLink href="tel:90743548">907 43 548</StyledLink> eller Marte
          Uvaag <StyledLink href="tel:93410594">934 10 594</StyledLink>
        </p>
      </AboutDiv>
    </Container>
  </Section>
);

const AboutDiv = styled.div`
  p {
    font-size: 1.2rem;
    line-height: 1.6;
  }
`;

const Art = styled.figure`
  width: 800px;
  margin: -80px 0;
  position: absolute;
  top: 0;
  left: 70%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 20%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const ArtMobile = styled.figure`
  width: 100%;
  margin: 0;
  display: none;
  margin-top: 64px;
  margin-bottom: -60%;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`;

const Title = styled.p`
  margin-top: 16px;
  color: ${props => props.theme.color.black.regular};
`;

const Subtitle = styled.p`
  ${props => props.theme.font_size.small};
  color: ${props => props.theme.color.black.light};
`;

const StyledLink = styled.a`
  color: ${props => props.theme.color.black.regular};
`

export default Info;
