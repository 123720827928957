import React from 'react';

import Layout from '@common/Layout';
import Navbar from '@common/Navbar';
import Header from '@sections/Header';
import About from '@sections/About';
import Faq from '@sections/Faq';
import Footer from '@sections/Footer';
import Info from '@sections/Info';
import Map from '@sections/Map';

const IndexPage = () => (
  <Layout>
    <Navbar />
    <Header />
    <Info />
    <About />
    <Map />
    <Footer />
  </Layout>
);

export default IndexPage;
